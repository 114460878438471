<template>
  <div>
    <h4 class="text-center">{{ $t("label.paymentGateway") }}</h4>
    <CardBillingInfo />
  </div>
</template>

<script>
import CardBillingInfo from "../../../components/Cards/CardBillingInfo.vue";

export default {
  name: "PaymentGateway",
  components: { CardBillingInfo },

  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
