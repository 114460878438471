<template>
  <!-- Billing Information Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
  >
    <template #title>
      <h6 class="font-semibold m-0">{{ $t("label.billingInformation") }}</h6>
    </template>
    <a-card :bordered="false" class="card-billing-info">
      <div class="col-info">
        <a-descriptions
          :title="`${currentUser.first_name} ${currentUser.last_name}`"
        >
          <a-descriptions-item :label="$t('label.address')">
            Vientaine
          </a-descriptions-item>
          <a-descriptions-item :label="$t('form.city')">
            Saysettha
          </a-descriptions-item>
          <a-descriptions-item :label="$t('label.zipPostal')">
            00004
          </a-descriptions-item>
          <a-descriptions-item :label="$t('form.country')">
            LA
          </a-descriptions-item>
          <a-descriptions-item :label="$t('form.phone')">
            +8562097820027
          </a-descriptions-item>
          <a-descriptions-item :label="$t('form.email')">
            jueyang96@gmail.com
          </a-descriptions-item>
          <a-descriptions-item>
            <hr class="color-red" />
            <div class="container">
              <span class="title">{{ $t("label.amount") }}:</span>
              <a-statistic
                prefix="₭"
                :precision="2"
                v-bind:value="500000"
                valueStyle=" font-family:'NotoSans'; font-size: 20px; margin-top:7px"
              >
              </a-statistic>
            </div>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="col-action">
        <a-button @click="makePayment" type="danger" size="large">
          <span class="text-white">{{ $t("button.next") }}</span>
        </a-button>
      </div>
    </a-card>
  </a-card>
  <!-- / Billing Information Card -->
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    makePayment() {
      return this.$router.push(`/payment/202304-00005/billing`);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;

  .title {
    padding: 8px;
    font-size: 20px;
  }
}
</style>
